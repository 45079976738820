/*@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');*/

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*!*/
  /*'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: 'Source Sans Pro', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  display: flex;
  position: relative;
}

.content {
  overflow-y: scroll;
  box-shadow: 0px 0 10px 0px #505050;
  z-index: 3;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #dfdfdf;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4b5a78;
}

/* Handle on Hover */
::-webkit-scrollbar-thumb:hover {
  background: #555555;
}

@media print {

  .ps-sidebar-root {
    display: none !important;
  }

  html,
  body,
  #root,
  .app,
  .content {
    height: auto !important;
  }

  .content {
    overflow-y: auto !important;
  }

  .MuiDataGrid-main,
  .MuiDataGrid-virtualScroller {
    height: auto!important;
    margin-bottom: 30px !important;
    padding-bottom: 30px !important;
  }

  .MuiDataGrid-footerContainer {
    /*display: none !important;*/
    visibility: hidden !important;
  }

}